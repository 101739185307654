import { bus } from 'react-icons-kit/fa/bus'
import { user } from 'react-icons-kit/fa/user'
import { Icon } from 'react-icons-kit'
import { Marker, Popup } from 'react-map-gl'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip, Card, CardBody, CardText } from 'reactstrap'
// import format from 'date-fns/format'
// import isYesterday from 'date-fns/is_yesterday'
import { routeToHTMLColor } from '../../services/routes'

class BusMarkerPopup extends React.Component {
  constructor(props) {
    super(props)

    this._togglePopup = this._togglePopup.bind(this)

    this.state = {
      popupOpen: false,
    }
  }

  _togglePopup() {
    this.setState({
      popupOpen: !this.state.popupOpen,
    })
  }

  render() {
    const { driver, id, latitude, longitude, timestamp } = this.props
    const { popupOpen } = this.state
    const tooltipId = `Tooltip${id}`
    const timeStampDate = new Date(timestamp)
    //console.log(driver.DisplayName + ' ' + timeStampDate)
    // const lastLocationUpdate = isYesterday(timeStampDate)
    //   ? format(timeStampDate, 'M-D-YYYY h:MM A')
    //   : format(timeStampDate, 'h:MM A')

    //console.log(lastLocationUpdate)
    const routeColor = routeToHTMLColor(driver.Route)
    return (
      <>
        <Marker
          latitude={latitude}
          longitude={longitude}
          // offsetLeft={-20}
          // offsetTop={-10}
          captureDoubleClick={true}
          captureClick={true}
        >
          <Icon icon={bus} onClick={() => this._togglePopup()} />
        </Marker>
        {driver && popupOpen && (
          <Popup
            latitude={latitude}
            longitude={longitude}
            closeButton={true}
            closeOnClick={false}
            anchor="bottom"
            dynamicPosition={false}
            captureDrag={false}
            captureClick={true}
            captureDoubleClick={true}
            onClose={() => this._togglePopup()}
          >
            <Card
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                textAlign: 'left',
              }}
            >
              {/* <CardImg
                  top
                  width="100%"
                  src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Profile%C3%97180&w=318&h=180"
                  alt="Card image cap"
                /> */}

              <CardBody>
                <Icon icon={user} size={'25%'} />
                <br />
                {driver.BusNumber && (
                  <>
                    <small className="text-muted">Bus#</small>
                    <CardText>{driver.BusNumber}</CardText>
                  </>
                )}
                {driver.DisplayName && (
                  <>
                    <small className="text-muted">Nickname</small>
                    <CardText>{driver.DisplayName}</CardText>
                  </>
                )}
                {driver.Route && (
                  <>
                    <small className="text-muted">Route</small>
                    <CardText>{driver.Route}</CardText>
                  </>
                )}
                {timestamp && (
                  <>
                    <small className="text-muted">last location update</small>
                    <CardText>{timeStampDate.toLocaleString()}</CardText>
                  </>
                )}
              </CardBody>
            </Card>
          </Popup>
        )}
      </>
    )
  }
}

BusMarkerPopup.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}

export default BusMarkerPopup
