export const routeToHTMLColor = route => {
  switch (route) {
    case 'Montana':
      return 'red'
    case 'Rond Schottegat':
      return 'purple'
    case 'Sta. Maria':
      return 'green'
    default:
      return 'black'
  }
}
