import React from 'react'
// import { Link } from 'gatsby'
// import { Button } from 'reactstrap'

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
import Map from '../containers/Map'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Unda shows the live location of buses on Curacao using your web browser on your desktop or phone"
      keywords={[`unda`, `curacao`, `bus`, `konvoi`, `transport`]}
    />
    <Map />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p> */}
    {/* <Button color="danger">Danger!</Button> */}
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
