// 3rd party
import { crosshairs } from 'react-icons-kit/fa/crosshairs'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Icon } from 'react-icons-kit'
import React, { Component } from 'react'
import ReactMapGL, { NavigationControl } from 'react-map-gl'

// local
import BusMarker from './components/BusMarker'
import UserMarker from './components/UserMarker'
import { withFirebase } from '../../components/FirebaseContext'
import BusMarkerPopup from './components/BusMarkerPopup'

class Map extends Component {
  constructor(props) {
    super(props)

    this.state = {
      driverLocationsArray: [],
      driversArray: [],
      locateUserEnabled: true,
      tooltipOpen: false,
      userLocationLatitude: 0,
      userLocationLongitude: 0,
      viewport: {
        width: 0,
        height: 0,
        latitude: 12.1696,
        longitude: -68.99,
        zoom: 10,
      },
    }

    this._locateUser = this._locateUser.bind(this)
    this._onViewportChange = this._onViewportChange.bind(this)
    this._updateDimensions = this._updateDimensions.bind(this)
  }

  targetElementNoScroll = null
  driverLocationsOnValueChange = null
  driversOnValueOnce = null

  _locateUser(withZoom = false, updateViewPortLatLon = false) {
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser')
      return
    }

    this.setState({ locateUserEnabled: false }, () => {
      navigator.geolocation.getCurrentPosition(
        position => {
          //console.log('getCurrentPosition', position)
          const zoom = withZoom === true ? 15 : this.state.viewport.zoom
          const viewportLatLon =
            updateViewPortLatLon === true
              ? [position.coords.latitude, position.coords.longitude]
              : [this.state.viewport.latitude, this.state.viewport.longitude]

          this.setState({
            locateUserEnabled: true,
            userLocationLatitude: position.coords.latitude,
            userLocationLongitude: position.coords.longitude,
            viewport: {
              ...this.state.viewport,
              latitude: viewportLatLon[0],
              longitude: viewportLatLon[1],
              zoom: zoom,
            },
          })
        },
        error => {
          console.log(error)
          this.setState({ locateUserEnabled: true })
        }
      )
    })
  }

  _onViewportChange = viewport => {
    //console.log('_onViewportChange')
    this.setState({ viewport })
  }

  _updateDimensions() {
    //console.log('_updateDimensions')
    this.setState({
      viewport: {
        ...this.state.viewport,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  render() {
    return (
      <>
        <ReactMapGL
          mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_ACCESS_TOKEN}
          mapStyle="mapbox://styles/ctrlemo/cjqch1ugq7xry2smx72tjrqfm"
          {...this.state.viewport}
          onViewportChange={this._onViewportChange}
        >
          <UserMarker
            latitude={this.state.userLocationLatitude}
            longitude={this.state.userLocationLongitude}
          />
          {this.state.driverLocationsArray.map(dl => {
            const driver = this.state.driversArray.find(
              driver => driver.id === dl.id
            )
            return driver !== undefined ? (
              <BusMarkerPopup
                key={dl.id}
                id={dl.id}
                latitude={dl.Latitude}
                longitude={dl.Longitude}
                timestamp={dl.Timestamp}
                driver={driver}
              />
            ) : null
          })}
          <div style={{ position: 'absolute', right: 0 }}>
            <NavigationControl onViewportChange={this._onViewportChange} />
          </div>
        </ReactMapGL>
        <button
          onClick={() => this._locateUser(true, true)}
          style={{
            color: this.state.locateUserEnabled ? `black` : `grey`,
            backgroundColor: `transparent`,
            border: 0,
            outline: 0,
            position: `absolute`,
            bottom: `5%`,
            right: `0%`,
          }}
          disabled={!this.state.locateUserEnabled}
        >
          <Icon icon={crosshairs} size={32} />
        </button>
      </>
    )
  }
  componentDidMount() {
    this._updateDimensions()
    window.addEventListener('resize', this._updateDimensions)

    // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
    this.targetElementNoScroll = document.getElementsByTagName(`body`)[0]
    // Disable body scroll
    disableBodyScroll(this.targetElementNoScroll)
    this._locateUser(false, false)
    const { firebase } = this.props
    this.driverLocationsOnValueChange = firebase
      .database()
      .ref('/driverLocations')
      .on('value', snapshot => {
        //console.log(snapshot)
        const driverLocationsObject = snapshot.val()
        //console.log(Object.entries(driverLocationsObject))
        const driverLocationsKeyValuePairs = Object.entries(
          driverLocationsObject
        )
        const driverLocationsArray = driverLocationsKeyValuePairs.map(kvp => {
          return { id: kvp[0], ...kvp[1] }
        })
        this.setState({
          driverLocationsArray,
        })
      })

    this.driversOnValueOnce = firebase
      .database()
      .ref('/drivers')
      .once('value')
      .then(snapshot => {
        //console.log(snapshot)
        const snapshotValue = snapshot.val()
        //console.log(Object.entries(snapshotValue))
        const keyValuePairs = Object.entries(snapshotValue)
        const driversArray = keyValuePairs.map(kvp => {
          return { id: kvp[0], ...kvp[1] }
        })
        //console.log(driversArray)
        this.setState({
          driversArray,
        })
      })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions)
    enableBodyScroll(this.targetElementNoScroll)
    this.props.firebase
      .database()
      .ref('/driverLocations')
      .off(`value`, this.driverLocationsOnValueChange)
  }
}
export default withFirebase(Map)
