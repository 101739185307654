import { bus } from 'react-icons-kit/fa/bus'
import { user } from 'react-icons-kit/fa/user'
import { Icon } from 'react-icons-kit'
import { Marker } from 'react-map-gl'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip, Card, CardBody, CardText } from 'reactstrap'
// import format from 'date-fns/format'
// import isYesterday from 'date-fns/is_yesterday'
import { routeToHTMLColor } from '../../services/routes'

class BusMarker extends React.Component {
  constructor(props) {
    super(props)

    this._toggle = this._toggle.bind(this)
    this.state = {
      tooltipOpen: false,
    }
  }

  _toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }
  render() {
    const { driver, id, latitude, longitude, timestamp } = this.props
    const tooltipId = `Tooltip${id}`
    const timeStampDate = new Date(timestamp)
    //console.log(driver.DisplayName + ' ' + timeStampDate)
    // const lastLocationUpdate = isYesterday(timeStampDate)
    //   ? format(timeStampDate, 'M-D-YYYY h:MM A')
    //   : format(timeStampDate, 'h:MM A')

    //console.log(lastLocationUpdate)
    const routeColor = routeToHTMLColor(driver.Route)
    return (
      <Marker
        latitude={latitude}
        longitude={longitude}
        offsetLeft={0}
        offsetTop={0}
        captureDoubleClick={false}
        captureClick={false}
      >
        <div>
          <div id={tooltipId} href="#" style={{ color: routeColor }}>
            <Icon icon={bus} />
          </div>
          {driver && (
            <Tooltip
              placement="right"
              isOpen={this.state.tooltipOpen}
              target={tooltipId}
              toggle={this._toggle}
              autohide={false}
              innerClassName={'text-center'}
              style={{
                backgroundColor: 'white',
                color: 'black',
              }}
            >
              <Card style={{ borderColor: routeColor }}>
                {/* <CardImg
                  top
                  width="100%"
                  src="https://placeholdit.imgix.net/~text?txtsize=33&txt=Profile%C3%97180&w=318&h=180"
                  alt="Card image cap"
                /> */}

                <CardBody>
                  <Icon icon={user} size={'50%'} />
                  {driver.BusNumber && (
                    <>
                      <small className="text-muted">Bus#</small>
                      <CardText>{driver.BusNumber}</CardText>
                    </>
                  )}
                  {driver.DisplayName && (
                    <>
                      <small className="text-muted">Nickname</small>
                      <CardText>{driver.DisplayName}</CardText>
                    </>
                  )}
                  {driver.Route && (
                    <>
                      <small className="text-muted">Route</small>
                      <CardText>{driver.Route}</CardText>
                    </>
                  )}
                  {timestamp && (
                    <>
                      <small className="text-muted">last location update</small>
                      <CardText>{timeStampDate.toLocaleString()}</CardText>
                    </>
                  )}
                </CardBody>
              </Card>
            </Tooltip>
          )}
        </div>
      </Marker>
    )
  }
}

BusMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}

export default BusMarker
