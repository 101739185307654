import React from 'react'
import PropTypes from 'prop-types'

import { bullseye } from 'react-icons-kit/fa/bullseye'
import { Icon } from 'react-icons-kit'
import { Marker } from 'react-map-gl'

const UserMarker = ({ latitude, longitude }) => (
  <Marker
    latitude={latitude}
    longitude={longitude}
    // offsetLeft={-20}
    // offsetTop={-10}
    captureDoubleClick={false}
    captureClick={false}
  >
    <div style={{ color: 'blue' }}>
      <Icon icon={bullseye} />
    </div>
  </Marker>
)

UserMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}
export default UserMarker
